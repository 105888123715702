import {
  AsyncAutoCompleteFieldProps,
  AsyncAutoCompleteField,
} from "../Fields/Dropdowns/AsyncAutoComplete/AsyncAutoCompleteField";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { AsyncAutocompleteFieldValue } from "../Fields/types/fieldTypes";
import "./styles.scss";

interface GridViewsAutocompleteComponentProps
  extends AsyncAutoCompleteFieldProps {
  gridId: string;
  value: AsyncAutocompleteFieldValue;
}

const GridViewsAutocompleteComponent = (
  props: GridViewsAutocompleteComponentProps
) => {
  return (
    <div className="grid-views-autocomplete">
      <AsyncAutoCompleteField
        type="AsyncAutocomplete"
        apiBaseUrl={props.apiBaseUrl}
        odataUrl={props.odataUrl}
        getAuthRequestHeader={props.getAuthRequestHeader}
        value={props.value}
        label={props.label || "Views"}
        autoCompleteSettings={{
          query: new Query()
            .select(["label", "value"])
            .where(
              new Predicate("gridId", "equal", props.gridId).and(
                new Predicate("label", "notequal", "Auto")
              )
            )
            .sortBy("label"),
        }}
        onSubmit={props.onSubmit && props.onSubmit}
      />
    </div>
  );
};

export default GridViewsAutocompleteComponent;
